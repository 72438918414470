import React, { useState, useEffect } from 'react';
import { submitFeedback } from '../services/api';
import './FeedbackForm.css';

function FeedbackForm({ location, modelDatetime, weatherData }) {
  const [email, setEmail] = useState(localStorage.getItem('userEmail') || '');
  const [weatherScore, setWeatherScore] = useState('');
  const [activityType, setActivityType] = useState('');
  const [qualitativeScore, setQualitativeScore] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    localStorage.setItem('userEmail', email);
  }, [email]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const { hourlyData, dailyData } = weatherData;
    const currentHourData = hourlyData[0]; // Using the first hourly data point

    const feedbackData = {
      email,
      lat: location.latitude,
      lon: location.longitude,
      weatherScore,
      activityType,
      qualitativeScore,
      notes,
      timestamp: new Date().toISOString(),
      modelDatetime,
      weatherData: {
        modelRun: modelDatetime,
        currentTime: currentHourData.time,
        stokeScoreSun: currentHourData.stoke_score_sun,
        wbgtSun: currentHourData.wbgt_sun,
        wbgtShade: currentHourData.wbgt_shade,
        utciSun: currentHourData.utci_sun,
        utciShade: currentHourData.utci_shade,
        solarAltitude: currentHourData.altitude,
        temperature: currentHourData.temp_c,
        windSpeed: currentHourData.wind_mps,
        relativeHumidity: currentHourData.relhum_per,
        cloudCover: currentHourData.cloudcov_per,
        precipitation: currentHourData.precip_mm,
        dailyAverageStoke: dailyData[0].stoke_score_sun, // Using the first daily data point
      },
    };

    submitFeedback(feedbackData)
      .then((response) => {
        alert('Feedback submitted successfully!');
        // Reset form
        setWeatherScore('');
        setActivityType('');
        setQualitativeScore('');
        setNotes('');
      })
      .catch((error) => {
        console.error('Error submitting feedback:', error);
        alert('Error submitting feedback. Please try again.');
      });
  };

  return (
    <div>
      <h2>Submit Your Feedback</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Activity Type:</label>
          <select
            value={activityType}
            onChange={(e) => setActivityType(e.target.value)}
            required
          >
            <option value="">Select Activity</option>
            <option value="Rock Climbing">Rock Climbing</option>
            <option value="Mountain Biking">Mountain Biking</option>
            <option value="Road Biking">Road Biking</option>
            <option value="Hiking">Hiking</option>
          </select>
        </div>

        <div className="form-group">
          <label>Numeric StokeScore (0-100):</label>
          <input
            type="number"
            value={weatherScore}
            onChange={(e) => setWeatherScore(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Qualitative StokeScore:</label>
          <select
            value={qualitativeScore}
            onChange={(e) => setQualitativeScore(e.target.value)}
            required
          >
            <option value="">Select Score</option>
            <option value="Great">Great</option>
            <option value="Good">Good</option>
            <option value="Fair">Fair</option>
            <option value="Passing">Passing</option>
            <option value="Poor">Poor</option>
            <option value="Not Recommended">Not Recommended</option>
          </select>
        </div>

        <div className="form-group">
          <label>Notes:</label>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Additional notes..."
          />
        </div>

        <button type="submit">Submit Feedback</button>
      </form>
    </div>
  );
}

export default FeedbackForm;
