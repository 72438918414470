import axios from 'axios';

export const API_BASE_URL = 'https://weather-backend-1080748692976.us-central1.run.app';

export const fetchWeatherData = (lat, lon, timeZone, currentTime = null) => {
  const params = {
    lat,
    lon,
    timeZone,
  };

  if (currentTime) {
    params.currentTime = currentTime;
  }

  return axios.get(`${API_BASE_URL}/fetch-data`, { params });
};

export const submitFeedback = (feedbackData) => {
  return axios.post(`${API_BASE_URL}/submit-feedback`, feedbackData);
};
