import React from 'react';
import moment from 'moment';
import './WeatherDisplay.css'; // Import the CSS file for styles

const formatNumber = (num) => {
  if (num === null || num === undefined) {
    return '-';
  }
  return Number(num).toFixed(2);
};

function WeatherDisplay({ weatherData, modelDatetime }) {
  const { hourlyData, dailyData } = weatherData;

  const formatDateTime = (datetime) => {
    return moment(datetime).local().format('YYYY-MM-DD HH:mm');
  };

  // Conversion functions
  const kelvinToCelsius = (tempK) => {
    return tempK - 273.15;
  }

  const kelvinToFahrenheit = (tempK) => {
    return (tempK - 273.15) * 9 / 5 + 32;
  }

  const celsiusToFahrenheit = (tempC) => {
    return tempC * 9 / 5 + 32;
  };

  const mpsToMph = (speedMps) => {
    return speedMps * 2.23694;
  };

  const mmToInches = (mm) => {
    return mm * 0.0393701;
  };

  return (
    <div>
      <p>Model run: {formatDateTime(modelDatetime)}</p>

      <h3>Hourly Data</h3>
      {hourlyData.map((dataPoint, index) => {
        // Prepare data for the table
        const parameters = [
          {
            name: 'Stoke Score Sun',
            valueMetric: formatNumber(dataPoint.stoke_score_sun),
            unitMetric: '', // Assuming unitless
            valueUS: formatNumber(dataPoint.stoke_score_sun),
            unitUS: '', // Unitless
          },
          {
            name: 'WBGT Sun',
            valueMetric: formatNumber(dataPoint.wbgt_sun),
            unitMetric: '°C',
            valueUS: formatNumber(celsiusToFahrenheit(dataPoint.wbgt_sun)),
            unitUS: '°F',
          },
          {
            name: 'WBGT Shade',
            valueMetric: formatNumber(dataPoint.wbgt_shade),
            unitMetric: '°C',
            valueUS: formatNumber(celsiusToFahrenheit(dataPoint.wbgt_shade)),
            unitUS: '°F',
          },
          {
            name: 'UTCI Sun',
            valueMetric: formatNumber(dataPoint.utci_sun),
            unitMetric: '°C',
            valueUS: formatNumber(celsiusToFahrenheit(dataPoint.utci_sun)),
            unitUS: '°F',
          },
          {
            name: 'UTCI Shade',
            valueMetric: formatNumber(dataPoint.utci_shade),
            unitMetric: '°C',
            valueUS: formatNumber(celsiusToFahrenheit(dataPoint.utci_shade)),
            unitUS: '°F',
          },
          {
            name: 'Solar Altitude',
            valueMetric: formatNumber(dataPoint.altitude),
            unitMetric: 'degrees',
            valueUS: formatNumber(dataPoint.altitude),
            unitUS: 'degrees',
          },
          {
            name: 'Temperature',
            valueMetric: formatNumber(kelvinToCelsius(dataPoint.temp_c)),
            unitMetric: '°C',
            valueUS: formatNumber(kelvinToFahrenheit(dataPoint.temp_c)),
            unitUS: '°F',
          },
          {
            name: 'Wind Speed',
            valueMetric: formatNumber(dataPoint.wind_mps),
            unitMetric: 'm/s',
            valueUS: formatNumber(mpsToMph(dataPoint.wind_mps)),
            unitUS: 'mph',
          },
          {
            name: 'Relative Humidity',
            valueMetric: formatNumber(dataPoint.relhum_per),
            unitMetric: '%',
            valueUS: formatNumber(dataPoint.relhum_per),
            unitUS: '%',
          },
          {
            name: 'Cloud Cover',
            valueMetric: formatNumber(dataPoint.cloudcov_per),
            unitMetric: '%',
            valueUS: formatNumber(dataPoint.cloudcov_per),
            unitUS: '%',
          },
          {
            name: 'Precipitation',
            valueMetric: formatNumber(dataPoint.precip_mm),
            unitMetric: 'mm/hr',
            valueUS: formatNumber(mmToInches(dataPoint.precip_mm)),
            unitUS: 'in/hr',
          },
        ];

        return (
          <div key={index}>
            <p>Time: {formatDateTime(dataPoint.time)}</p>
            <table className="weather-table">
              <thead>
                <tr>
                  <th>Parameter</th>
                  <th>Value (Metric)</th>
                  <th>Value (US)</th>
                </tr>
              </thead>
              <tbody>
                {parameters.map((param, idx) => (
                  <tr key={idx}>
                    <td>{param.name}</td>
                    <td>
                      {param.valueMetric} {param.unitMetric}
                    </td>
                    <td>
                      {param.valueUS} {param.unitUS}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <hr />
          </div>
        );
      })}

      <h3>Daily Average (Daylight)</h3>
      {dailyData.map((dataPoint, index) => (
        <div key={index}>
          <p>Date: {formatDateTime(dataPoint.time)}</p>
          <p>Stoke Score: {formatNumber(dataPoint.stoke_score_sun)}</p>
          <hr />
        </div>
      ))}
    </div>
  );
}

export default WeatherDisplay;
