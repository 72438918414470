import React, { useEffect, useState } from 'react';
import WeatherDisplay from './components/WeatherDisplay';
import FeedbackForm from './components/FeedbackForm';
import { fetchWeatherData } from './services/api';
import { ClipLoader } from 'react-spinners';

// Import Day.js and plugins
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import './App.css';

dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const [weatherData, setWeatherData] = useState(null);
  const [modelDatetime, setModelDatetime] = useState(null);
  const [location, setLocation] = useState({ latitude: 37.7749, longitude: -122.4194 }); // Example coordinates
  const [timeZone, setTimeZone] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [loading, setLoading] = useState(false); // For loading indicator

  useEffect(() => {
    // Get user's location
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });
      },
      (error) => {
        console.error('Error obtaining location:', error);
      }
    );

    // Get user's time zone
    let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (!userTimeZone) {
      // Fallback to a default time zone if detection fails
      userTimeZone = 'UTC';
      console.warn('Could not detect user time zone. Defaulting to UTC.');
    }

    setTimeZone(userTimeZone);
  }, []);

  useEffect(() => {
    if (location && timeZone) {
      const currentTimeISO = dayjs().format(); // Local time with offset
      setCurrentTime(currentTimeISO); // Save current time in state

      setLoading(true); // Start loading
      fetchWeatherData(location.latitude, location.longitude, timeZone, currentTimeISO)
        .then((response) => {
          let { hourlyData, dailyData, modelDatetime } = response.data;

          // Normalize data to arrays
          if (!Array.isArray(hourlyData)) {
            hourlyData = [hourlyData];
          }
          if (!Array.isArray(dailyData)) {
            dailyData = [dailyData];
          }

          setWeatherData({ hourlyData, dailyData });
          setModelDatetime(modelDatetime);
          console.log(hourlyData);
          console.log(dailyData);
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.error) {
            // Display the error message from the API
            alert(`Error: ${error.response.data.error}`);
          } else {
            // Handle other errors
            console.error('Error fetching weather data:', error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [location, timeZone]);

  return (
    <div className="App">
      <h1>StokeScore Feedback</h1>
      <p>
        User latitude: {location.latitude}
        <br />
        User longitude: {location.longitude}
        <br />
        Time Zone: {timeZone}
        <br />
        Current Time: {dayjs(currentTime).format('YYYY-MM-DD HH:mm:ss Z')}
      </p>
      {loading ? (
        <ClipLoader color="#3498db" loading={loading} size={50} />
      ) : weatherData ? (
        <>
          <WeatherDisplay weatherData={weatherData} modelDatetime={modelDatetime} />
          <FeedbackForm location={location} modelDatetime={modelDatetime} weatherData={weatherData} />
        </>
      ) : (
        <div>No weather data available.</div>
      )}
    </div>
  );
}

export default App;
